<template>
  <ep-layout class="el-body sharings-show">
    <div v-if="sharing && sharing.length > 0" class="el-panel">
      <el-toolbar :sticky="true" class="mb-4">
        <!-- eslint-disable-next-line -->
        <button class="el-icon-button el-icon-button--big icon-previous_page" @click="goBack"></button>
        <h1 class="el-main-title">
          {{ obj.recipientName}}
          <span class="el-subtitle">
            <span v-if="(isEditing)">{{$t('profile.isEditing')}}</span>
            <span v-else>{{$t('profile.isViewing')}}</span>
          </span>
        </h1>
        <el-toolbar-spacer />
        <el-rectangle-button-container :grey="true">
          <el-rectangle-button v-if="!(isEditing)" @click.native="Edit()">
            <span class="icon-edit"></span>
            {{$t('sharing.toolbar.button.edit')}}
          </el-rectangle-button>
        </el-rectangle-button-container>
        <el-rectangle-button-container :grey="true">
          <el-rectangle-button @click.native="showModal">
            <span class="icon-supprime"></span>
            {{$t('documents.toolbar.button.delete')}}
          </el-rectangle-button>
        </el-rectangle-button-container>
      </el-toolbar>
      <div>
        <h2 class="el-title">{{ $t('sharing.parameters')}}</h2>
        <br />
        <form @submit.prevent="updateShare()">
          <div class="ep-input-group--small-size">
            <label class="el-input-group__label">
              {{ $t('sharing.create.label.recipientName')}}<span v-if="isEditing">*</span>
            </label>
            <p v-if="!(isEditing)">{{ obj.recipientName}}</p>
            <input
              v-if="isEditing"
              class="el-input-group__input ep-size"
              type="text"
              ref="recipientName"
              name="recipientName"
              :value="obj.recipientName"
              :required="isEditing"
            />
          </div>

          <div class="ep-input-group--small-size">
            <label class="el-input-group__label">
              {{ $t('sharing.create.label.recipientEmail')}}
            </label>
            <p>{{obj.recipientMail}}</p>
          </div>

          <div class="ep-input-group--small-size">
            <el-toolbar>
              <label class="el-input-group__label">
                {{ $t('sharing.create.label.expiration') }}
                <span v-if="isEditing">*</span>
              </label>
              <el-toolbar-spacer />
              <span class="mb-1" v-if="isEditing">
                {{ $tc('sharing.create.input.expiration.value',
                duration,
                { n: duration }) }}
              </span>
            </el-toolbar>
            <input
              v-if="isEditing"
              class="ep-input-group__range"
              type="range"
              ref="duration"
              v-model="duration"
              min="1"
              max="60"
              :required="isEditing"
            />
            <p v-else>{{obj.duration}}</p>
          </div>
          <div class="ep-input-group--small-size">
            <label class="el-input-group__label">{{ $t('sharing.table.head.consulted')}}</label>
            <label v-if="obj.consulted">{{ $t('sharing.table.cell.consulted.no')}}</label>
            <label v-else>{{ $t('sharing.table.cell.consulted.yes')}}</label>
          </div>

          <div class="ep-input-group--small-size">
            <label class="el-input-group__label">
              {{ $t('sharing.table.head.startingDate')}}<span v-if="isEditing">*</span>
            </label>
            <!-- eslint-disable-next-line -->
            <p
              v-if="!(isEditing)"
            >{{ obj.startingDate.split(' ')[0].split('-').reverse().join('/') }}</p>
            <input
              v-if="isEditing"
              type="date"
              class="el-input-group__input ep-size"
              ref="startDate"
              name="startDate"
              :value="date"
              required
            />
          </div>
          <div class="ep-input-group--small-size">
            <label class="el-input-group__label">{{ $t('sharing.table.head.endingDate')}}</label>
            <p>{{ obj.endingDate.split(' ')[0].split('-').reverse().join('/') }}</p>
          </div>
          <div class="el-toolbar el-toolbar--bottom el-toolbar--fixed" v-if="isEditing">
            <button class="el-button">{{$t('profile.save')}}</button>
            <button
              class="el-button el-button--inverted"
              @click.prevent="Cancel()"
            >{{$t('profile.cancel')}}</button>
          </div>
        </form>
        <el-toolbar>
          <h2 class="el-title">{{ $t('profile.sharedDocs')}}</h2>
          <el-toolbar-spacer />
          <el-rectangle-button-container v-if="selected.length > 0" :grey="true">
            <el-rectangle-button>
              <span class="icon-download"></span>
              {{ $t('sharing.toolbar.button.download')}}
            </el-rectangle-button>
          </el-rectangle-button-container>
        </el-toolbar>
        <el-table>
          <el-table-header>
            <el-table-row>
              <el-table-head data-type="input">
                <input
                  class="el-checkbox"
                  @click.stop
                  type="checkbox"
                  v-model="selectAll"
                  @click="select"
                />
              </el-table-head>
              <el-table-head :main="true">{{ $t('sharing.show.table.head.type') }}</el-table-head>
              <el-table-head :main="true">{{ $t('sharing.show.table.head.title') }}</el-table-head>
              <el-table-head>{{ $t('sharing.show.table.head.date') }}</el-table-head>
            </el-table-row>
          </el-table-header>
          <el-table-body>
            <el-table-row v-for="(item, index) in sharing" :key="index">
              <el-table-cell @click.native.stop data-type="input">
                <input
                  class="el-checkbox"
                  @click.stop
                  :value="item.id"
                  type="checkbox"
                  v-model="selected"
                />
              </el-table-cell>
              <el-table-cell :main="true">
                <div v-if="item.type==='folder'" class="icon-folder icon--table--orange"></div>
                <div v-else class="icon-document icon--table--orange"></div>
              </el-table-cell>
              <el-table-cell :main="true">{{ item.name }}</el-table-cell>
              <el-table-cell>{{ item.depositDate }}</el-table-cell>
              <el-table-cell data-type="button">
                <el-rectangle-button-container>
                  <el-rectangle-button @click.native="downloadFile(item)">
                    <span class="icon-download"></span>
                    {{ $t('sharing.toolbar.button.download')}}
                  </el-rectangle-button>
                  <!-- <el-rectangle-button-child v-if="(isEditing)">
                    <el-rectangle-button @click.native="deleteSelected">
                      <span class="icon-supprime"></span>
                      {{ $t('sharing.toolbar.button.delete')}}
                    </el-rectangle-button>
                  </el-rectangle-button-child>-->
                </el-rectangle-button-container>
              </el-table-cell>
            </el-table-row>
            <el-table-row v-if="sharing.length === 0">
              <el-table-cell colspan="5">Aucune donnée à afficher...</el-table-cell>
            </el-table-row>
          </el-table-body>
        </el-table>
      </div>
    </div>


    <!--modal Delete-->
    <div class="el-dialog" v-show='modalVisible'>
      <div class="el-dialog__container">
        <div class="el-panel">
            <h2 className="el-title">{{$t('sharing.deletetion.single.confirmation')}}</h2>
          <form v-on:submit.prevent="deleteMe">
          <div class="ep-button--panel-middle">
            <button
              class="el-button el-button--inverted"
              @click.prevent="showModal"
            >{{ $t('sharing.create.button.cancel') }}</button>
            <button
              class="el-button"
              type="submit"
            >{{ $t('sharing.create.button.delete') }}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </ep-layout>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import downloadjs from 'downloadjs';
import EpLayout from '@/layouts/Default.vue';

export default {

  name: 'SharingShow',


  props: {

    sharingIndex: {
      type: [Number],
    },
    sharingId: {
      type: [String],
    },
  },


  computed: {
    ...mapGetters({
      userShares: 'sharings/userShares',
    }),
    date() {
      const d = this.obj.startingDate.split(' ');
      return d[0];
    },
  },


  components: {
    EpLayout,
  },


  data() {
    return {
      obj: null,
      isEditing: false,
      isDialogOpen: false,
      sharing: null,
      selected: [],
      selectAll: false,
      duration: undefined,
      share: {
        recipientName: '',
        recipientEmail: '',
        duration: 7,
        fileIds: [],
      },
      modalVisible: false,
    };
  },


  methods: {
    ...mapActions({
      fetchSharings: 'sharings/all',
      findSharingById: 'sharings/findById',
      update: 'sharings/update',
      delete: 'sharings/delete',
      downloadMyFile: 'documents/downloadMyFile',
    }),
    downloadFile(file) {
      this.downloadMyFile(file.id).then((response) => {
        let filename = file.name;
        if (!filename.endsWith(`.${file.extension}`)) {
          filename = `${filename}.${file.extension}`;
        }
        downloadjs(response, filename);
      });
    },
    deleteMe() {
      this.delete(this.sharingId).then(() => {
        this.fetchSharings().then(() => {
          this.$router.push({
            name: 'sharings.index',
            params: { updateMe: true },
          });
        });
      });
    },
    Cancel() {
      this.isEditing = false;
      this.duration = this.obj.duration;
    },
    updateShare() {
      const d = this.$refs.startDate.value;

      const o = {
        sharingId: this.obj.id,
        recipientMail: this.obj.recipientMail,
        recipientName: this.$refs.recipientName.value,
        startingDate: d,
        sharingDuration: this.duration,
      };

      this.update(o).then(() => {
        this.fetchSharings().then(() => {
          this.obj = this.userShares[this.sharingIndex].sharings.find(
            obj => obj.id === this.sharingId,
          );
          this.duration = this.obj.duration;
        });
        this.isEditing = false;
      });
    },

    Edit() {
      this.isEditing = true;
    },
    openDialog() {
      // eslint-disable-next-line no-console
      console.log(this.sharingId);
      const sharing = this.all[this.sharingIndex].sharings.find(
        share => share.id === this.sharingId,
      );
      // eslint-disable-next-line no-console
      console.log(sharing);
      this.share.recipientName = sharing.recipientName;
      // eslint-disable-next-line max-len
      this.share.recipientEmail = sharing.recipientMail;
      this.share.duration = sharing.duration;
      this.isDialogOpen = true;
    },
    closeDialog() {
      this.isDialogOpen = false;
    },
    deleteSelected() {},
    goBack() {
      this.$router.push({
        name: 'sharings.index',
        params: { sharingIndex: this.sharingIndex },
      });
    },

    select() {
      this.selected = [];
      if (!this.selectAll) {
        Object.values(this.sharing).forEach((value) => {
          this.selected.push(value.id);
        });
      }
    },

    showModal() {
      this.modalVisible = !this.modalVisible;
    },
  },

  mounted() {
    if (!this.userShares) {
      this.fetchSharings();
    }

    if (this.sharingIndex !== undefined) {
      this.obj = this.userShares[this.sharingIndex].sharings.find(
        obj => obj.id === this.sharingId,
      );
      this.duration = this.obj.duration;

      this.findSharingById(this.sharingId).then((response) => {
        this.sharing = response;
        // this.share.fileIds = this.sharing.map(item => item.id);
      });
    } else {
      this.$router.push({
        name: 'sharings.index',
      });
    }
  },

  watch: {
    sharingId(value) {
      this.selected = [];
      if (value !== null) {
        this.findSharingById(value).then((response) => {
          this.sharing = response;
        });
        // this.sharings = this.searchSharingById(this.all, this.sharingId);
      }
    },
  },
};
</script>
